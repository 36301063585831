import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-open", "onClick"]
const _hoisted_2 = {
  key: 1,
  style: {"margin-top":"0px"}
}
const _hoisted_3 = { class: "editing-form-group-fields-headers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CreateVocEntryButton = _resolveComponent("CreateVocEntryButton")!
  const _component_AttrsComponent = _resolveComponent("AttrsComponent")!
  const _component_EditableHelpComponent = _resolveComponent("EditableHelpComponent")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.groupFilled)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupFilled, (groupDetailsFilled) => {
          return (_openBlock(), _createElementBlock("div", {
            key: groupDetailsFilled.key,
            class: "editing-form editing-form-container"
          }, [
            (groupDetailsFilled.caption)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([groupDetailsFilled.class, "editing-form-group-header"]),
                  "data-open": _ctx.getOpenHeader(groupDetailsFilled),
                  style: _normalizeStyle(groupDetailsFilled.style),
                  onClick: ($event: any) => (_ctx.toggleOpenHeader(groupDetailsFilled))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(groupDetailsFilled.captionClass),
                    style: _normalizeStyle(groupDetailsFilled.captionStyle)
                  }, _toDisplayString(groupDetailsFilled.caption), 7),
                  _createVNode(_component_ButtonComponent, {
                    icon: _ctx.TABLE_ICONS.chevronDown,
                    iconRotate180: _ctx.getOpenHeader(groupDetailsFilled),
                    class: "button-opacity"
                  }, null, 8, ["icon", "iconRotate180"])
                ], 14, _hoisted_1))
              : (_openBlock(), _createElementBlock("div", _hoisted_2)),
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupDetailsFilled.headers, (headerFilled) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: headerFilled.key,
                  class: _normalizeClass([headerFilled.class, "editing-form-group-fields-headers-root"]),
                  style: _normalizeStyle(headerFilled.style)
                }, [
                  (headerFilled.caption)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([headerFilled.captionClass, "editing-form-fields-header"]),
                        style: _normalizeStyle(headerFilled.captionStyle)
                      }, _toDisplayString(headerFilled.caption), 7))
                    : _createCommentVNode("", true),
                  _createVNode(_component_AttrsComponent, {
                    "class-attrs-container": _ctx.getClassAttrsContainer(headerFilled),
                    "custom-help": Boolean(_ctx.vocType),
                    data: _ctx.currentData,
                    "empty-help": _ctx.emptyHelp,
                    "get-read-only": _ctx.getReadOnly,
                    "global-handlers": _ctx.GLOBAL_ATTRS_HANDLERS,
                    "models-dto": headerFilled.modelArray,
                    readOnly: _ctx.readOnly,
                    "style-attrs-container": headerFilled.attrsStyle,
                    types: _ctx.vocRepositoryDto.types,
                    "unique-id": _ctx.uniqueId,
                    onReset: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reset', $event))),
                    onChangeField: _ctx.onChangeField,
                    onClickHelp: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open.currentHelp = $event))
                  }, {
                    "voc-header-toolbar-start": _withCtx((payload) => [
                      (payload.model.vocDataSet?.type?.voc_type)
                        ? (_openBlock(), _createBlock(_component_CreateVocEntryButton, {
                            key: 0,
                            voc_type: payload.model.vocDataSet.type.voc_type,
                            onSuccess: ($event: any) => (payload.onUpdateVoc())
                          }, null, 8, ["voc_type", "onSuccess"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["class-attrs-container", "custom-help", "data", "empty-help", "get-read-only", "global-handlers", "models-dto", "readOnly", "style-attrs-container", "types", "unique-id", "onChangeField"])
                ], 6))
              }), 128))
            ], 512), [
              [_vShow, _ctx.getOpenHeader(groupDetailsFilled)]
            ])
          ]))
        }), 128))
      : (_openBlock(), _createBlock(_component_AttrsComponent, {
          key: 1,
          "custom-help": Boolean(_ctx.vocType),
          data: _ctx.currentData,
          "empty-help": _ctx.emptyHelp,
          "get-read-only": _ctx.getReadOnly,
          "global-handlers": _ctx.GLOBAL_ATTRS_HANDLERS,
          "models-dto": _ctx.vocRepositoryDto.form.table.model,
          readOnly: _ctx.readOnly,
          types: _ctx.vocRepositoryDto.types,
          "unique-id": _ctx.uniqueId,
          onReset: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reset', $event))),
          onChangeField: _ctx.onChangeField,
          onClickHelp: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open.currentHelp = $event))
        }, {
          "voc-header-toolbar-start": _withCtx((payload) => [
            (payload.model.vocDataSet?.type?.voc_type)
              ? (_openBlock(), _createBlock(_component_CreateVocEntryButton, {
                  key: 0,
                  voc_type: payload.model.vocDataSet.type.voc_type,
                  onSuccess: ($event: any) => (payload.onUpdateVoc())
                }, null, 8, ["voc_type", "onSuccess"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["custom-help", "data", "empty-help", "get-read-only", "global-handlers", "models-dto", "readOnly", "types", "unique-id", "onChangeField"])),
    (_ctx.open.currentHelp && (_ctx.currentHelpValue || _ctx.vocExtraInfo?.edit.hint))
      ? (_openBlock(), _createBlock(_component_EditableHelpComponent, {
          key: 2,
          editable: _ctx.vocExtraInfo?.edit.hint,
          modelValue: _ctx.currentHelpValue,
          title: _ctx.open.currentHelp.model.caption,
          style: {"z-index":"1050"},
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.open.currentHelp = null)),
          onClickEdit: _ctx.onOpenHelpEdit
        }, null, 8, ["editable", "modelValue", "title", "onClickEdit"]))
      : _createCommentVNode("", true),
    (_ctx.open.enterModal)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 3,
          "model-value": _ctx.open.enterModal.modelValue,
          readonly: _ctx.open.enterModal.readonly,
          title: _ctx.open.enterModal.title,
          "title-save-button": _ctx.open.enterModal.titleSaveButton,
          onClose: _ctx.open.enterModal.onClose,
          "onUpdate:modelValue": _ctx.open.enterModal.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "title-save-button", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}