import { UserSettingsService } from "@/services/UserSettingsService/UserSettingsService";
import { USER_SETTINGS_KEYS } from "@/services/UserSettingsService/consts";
import { SettingsTableAppearanceDto } from "@/modules/settings-appearance/helpers";
import {
  DocumentStyleHelper,
  Logger,
  TABLE_CLASSES,
  TABLE_STYLES,
  TableDataSet,
  TableStylesHelper,
  UseSaveSettingsInTableDataSet,
} from "table";
import { TableEmitColResize } from "table/dist/types/TableEmitColResize";
import { TableSettingsObj } from "table/dist/components/TableComponent/common/services/TableSettingsService/TableSettingsObj";
import { DEFAULT_SETTINGS } from "@/common/consts";
import {
  TableDataSetListener,
  TableDataSetListenerEvent,
} from "table/dist/classes/Table/TableDataSet/TableDataSet";

export interface SaveColSettings {
  width?: string;
}

// методы для работы с настройками пользователя
export const currentSettings = {
  getTableAppearanceDto: async () => {
    return (
      (await UserSettingsService.get<SettingsTableAppearanceDto>(
        USER_SETTINGS_KEYS.TABLE_APPEARANCE,
      )) || DEFAULT_SETTINGS.tableAppearance
    );
  },
  getHints: async (): Promise<boolean> => {
    return (
      (await UserSettingsService.get<boolean>(USER_SETTINGS_KEYS.HINTS)) ??
      DEFAULT_SETTINGS.hints
    );
  },
  saveHints: async (value: boolean) => {
    return UserSettingsService.set(USER_SETTINGS_KEYS.HINTS, value);
  },
  getWordWrap: async (): Promise<boolean> => {
    return (
      (await UserSettingsService.get<boolean>(USER_SETTINGS_KEYS.WORD_WRAP)) ??
      DEFAULT_SETTINGS.wordWrap
    );
  },
  saveWordWrap: async (value: boolean) => {
    return UserSettingsService.set(USER_SETTINGS_KEYS.WORD_WRAP, value);
  },
  getEdit: async (): Promise<boolean> => {
    return (
      (await UserSettingsService.get<boolean>(USER_SETTINGS_KEYS.EDIT)) ??
      DEFAULT_SETTINGS.edit
    );
  },
  saveEdit: async (value: boolean) => {
    return UserSettingsService.set(USER_SETTINGS_KEYS.EDIT, value);
  },
  getSizeReportFormBottom: () => {
    return UserSettingsService.get<number>(
      USER_SETTINGS_KEYS.SIZE_REPORT_FORM_BOTTOM,
    );
  },
  saveSizeReportFormBottom: (value: number | undefined) => {
    return UserSettingsService.set(
      USER_SETTINGS_KEYS.SIZE_REPORT_FORM_BOTTOM,
      value,
    );
  },
  getColSettingsKey(id: string) {
    return USER_SETTINGS_KEYS.SAVE_COL_SETTINGS + "_" + id;
  },
  getColSettingsId(tableDataSet: TableDataSet, field: string) {
    // через split по :: сможем получить название колонки в случае чего.
    return tableDataSet.id + "::" + field;
  },
  getColSettings: (id: string) => {
    return UserSettingsService.get<SaveColSettings>(
      currentSettings.getColSettingsKey(id),
    );
  },
  saveColSettings: async (
    id: string,
    value: SaveColSettings | undefined,
    assign?: boolean,
  ) => {
    if (assign && value) {
      const current = await currentSettings.getColSettings(id);
      if (current) {
        value = { ...current, ...value };
      }
    }

    return await UserSettingsService.set(
      currentSettings.getColSettingsKey(id),
      value,
    );
  },
  saveColSize: (tableDataSet: TableDataSet, event: TableEmitColResize) => {
    const id = currentSettings.getColSettingsId(tableDataSet, event.col_name);
    return currentSettings.saveColSettings(id, { width: event.width }, true);
  },
  initColSettings: async (tableDataSet: TableDataSet) => {
    const promisesColSettings = await Promise.all(
      tableDataSet.modelUnref.map((tableModel) => {
        return currentSettings
          .getColSettings(
            currentSettings.getColSettingsId(tableDataSet, tableModel.field),
          )
          .then((colSettings) => {
            return {
              tableModel,
              colSettings,
            };
          });
      }),
    );
    for (const { tableModel, colSettings } of promisesColSettings) {
      if (colSettings) {
        if (colSettings.width) {
          tableModel.setWidth(colSettings.width);
        }
      }
    }

    if (
      tableDataSet.tableName === "modal-select-table" &&
      promisesColSettings.length === 0
    ) {
      await tableDataSet.executeAutoWidth(false, false);
    }
  },
  getColResizeListener: (tableDataSet: TableDataSet) => {
    return {
      next: async (arg: TableDataSetListenerEvent<TableEmitColResize>) => {
        if (!arg.saveChanges) {
          return;
        }

        await currentSettings.saveColSize(tableDataSet, arg.event);
      },
    } as TableDataSetListener<TableDataSetListenerEvent<TableEmitColResize>>;
  },
  getTableSettingsObj(key: string) {
    return UserSettingsService.get<TableSettingsObj>(key);
  },
  saveTableSettingsObj: (
    key: string,
    settings: TableSettingsObj | undefined,
  ) => {
    return UserSettingsService.set(key, settings);
  },
};

// применить сохранённые настройки к таблице и настроить слушатели на сохранение
export const useSaveSettingsInTableDataSet: UseSaveSettingsInTableDataSet =
  async (tableDataSet: TableDataSet) => {
    tableDataSet.options.extra ??= {};
    if (tableDataSet.options.extra.__useSaveSettingsInTableDataSet) {
      Logger.warn("Сохранение настроек таблицы уже было применено.");
      return;
    }

    tableDataSet.options.extra.__useSaveSettingsInTableDataSet = true;

    tableDataSet.addOptionsListeners({
      colResize: currentSettings.getColResizeListener(tableDataSet),
    });
    tableDataSet.settingsService.opts.getSettings = async (key) => {
      return currentSettings.getTableSettingsObj(key);
    };
    tableDataSet.settingsService.opts.onSave = async (key, value) => {
      return currentSettings.saveTableSettingsObj(key, value);
    };
    await currentSettings.initColSettings(tableDataSet);
  };

const tableStylesHelper = new TableStylesHelper(TABLE_CLASSES);
const stylesHelper = new DocumentStyleHelper("page-styles-default");

export async function initUserSettings() {
  try {
    const tableAppearance = await currentSettings.getTableAppearanceDto();
    tableStylesHelper
      .addThBackground(tableAppearance.thBackground)
      .addContainerBackground(tableAppearance.containerBackground)
      .addTrBackground(tableAppearance.trBackground)
      .addTr2Background(tableAppearance.tr2Background)
      .addHeaderColor(tableAppearance.headerColor)
      .addTrColor(tableAppearance.trColor)
      .addBorderColor(tableAppearance.borderColor)
      .addSelectedHeaderBackground(tableAppearance.selectedHeaderBackground)
      .addTdSelectedCellBorderColor(tableAppearance.selectedCellBorderColor1)
      .addTdSelectedCell2BorderColor(tableAppearance.selectedCellBorderColor2)
      .addLinkColor(tableAppearance.linkColor)
      .addVisitedColor(tableAppearance.visitedColor)
      .addCellHeight(tableAppearance.cellHeight)
      .addPaddingInline(tableAppearance.paddingHorizontalCell)
      .addCellFontSize(tableAppearance.cellFontSize)
      .addHeaderFontSize(tableAppearance.headerFontSize)
      .addHeaderHeight(tableAppearance.headerHeight)
      .execute();
    const color =
      tableAppearance.selectedCellBorderColor1 ??
      TABLE_STYLES.selectedCell["border-color"];
    stylesHelper.addStyle(`
    .attr-input-container:focus-within, 
    .input-component-container:focus-within, 
    .select-container:focus-within,
    .button-opacity:focus-within,
    .button-close:focus-within,
    .form-button:focus-within,
    .root-radio-button-input .radio-button-input-value[data-v-afe822e0]:focus
     {
      outline: solid ${color} 1px;
    }
    `);
    stylesHelper.execute();
  } catch (ex) {
    Logger.error("initUserSettings", ex);
  }
}
