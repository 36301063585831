import { GlobalTableSettingsService } from "table";
import {
  currentSettings,
  useSaveSettingsInTableDataSet,
} from "@/services/UserSettingsService/helpers";
import { watch } from "vue";
import { DEFAULT_SETTINGS } from "@/common/consts";

export async function initGlobalTableSettings() {
  GlobalTableSettingsService.setUseSaveSettingsInTableDataSet(
    useSaveSettingsInTableDataSet,
  );
  GlobalTableSettingsService.globalSettings.wordWrap =
    await currentSettings.getWordWrap();
  watch(
    () => GlobalTableSettingsService.globalSettings.wordWrap,
    async () => {
      const wordWrap =
        GlobalTableSettingsService.globalSettings.wordWrap ??
        DEFAULT_SETTINGS.wordWrap;
      await currentSettings.saveWordWrap(wordWrap);
    },
  );
}
