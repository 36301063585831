import {
  PanelFiltersFactory,
  TableDataSet,
} from "table";
import { PanelFiltersData } from "table/dist/components/PanelFilters/types";

// применить фильтрацию к таблице
export function applyPanelFiltersData(
  tableDataSet: TableDataSet,
  data: PanelFiltersData,
) {
  const filterLogical = PanelFiltersFactory.toFilterLogical(data);
  tableDataSet.addOrReplaceInitialFilters([
    {
      key: "panel",
      filter: filterLogical,
    },
  ]);
}
