import {
  Dictionary,
  getValidError,
  RepositoryReadModelDto,
  RepositoryReadTypeDto,
  toStr,
  ValidErrorEnum,
} from "table";
import { ValidationHandler } from "table/dist/types/ValidationHandler";

export function getValidErrorsAttrs(
  attrs: Dictionary,
  models: RepositoryReadModelDto[],
  types: Dictionary<RepositoryReadTypeDto>,
  validationHandlers: Dictionary<ValidationHandler>,
): ValidErrorEnum[] {
  const errors: ValidErrorEnum[] = [];
  for (const model of models) {
    const error = getValidError(
      model,
      types[model.type!],
      attrs[model.field],
      validationHandlers,
    );
    if (error.type !== ValidErrorEnum.Ok) {
      errors.push(error.type);
    }
  }

  return errors;
}

export function getVocGroupEnableAttrs(
  modelArray: RepositoryReadModelDto[],
  data: Dictionary,
) {
  const enableAttrs = new Set<string>();
  modelArray.forEach((model) => {
    if (!model.enable_attrs) {
      return;
    }

    const value = data[model.field];
    const enableAttrsCurrentValue = model.enable_attrs[toStr(value)];
    if (!enableAttrsCurrentValue) {
      return;
    }

    enableAttrsCurrentValue.forEach((field) => {
      enableAttrs.add(field);
    });
  }, {} as Dictionary<boolean>);
  return enableAttrs;
}
