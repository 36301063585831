import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366aa9ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"width":"100%","box-sizing":"border-box"}
}
const _hoisted_2 = { class: "dropdown-container action-buttons-context-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIconComponent = _resolveComponent("ButtonIconComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ActionButton = _resolveComponent("ActionButton", true)!
  const _component_TooltipComponent = _resolveComponent("TooltipComponent")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.isDelimiter)
          ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_ButtonComponent, _mergeProps({
                ref: "buttonComponentRef",
                disabled: _ctx.disabled,
                icon: _ctx.dataObj.icon ? { src: _ctx.dataObj.icon } : undefined,
                title: _ctx.dataObj.title
              }, _ctx.props, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick($event, _ctx.dataObj)), ["stop"]))
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.dataObj.caption) + " ", 1),
                  (_ctx.dataObj.actions)
                    ? (_openBlock(), _createBlock(_component_ButtonIconComponent, {
                        key: 0,
                        "has-content": Boolean(_ctx.data.caption),
                        icon: _ctx.TABLE_ICONS.chevronUp,
                        "icon-rotate180": !_ctx.open.tooltip
                      }, null, 8, ["has-content", "icon", "icon-rotate180"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 16, ["disabled", "icon", "title"]),
              (_ctx.dataObj.actions)
                ? (_openBlock(), _createBlock(_component_TooltipComponent, {
                    key: 0,
                    ref: "tooltipRef",
                    onHide: _ctx.onHide
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataObj.actions, (action, i) => {
                          return (_openBlock(), _createBlock(_component_ActionButton, {
                            key: 
              typeof action === 'string'
                ? action + i
                : action.url + action.caption
            ,
                            "actions-func-dict": _ctx.actionsFuncDict,
                            class: _normalizeClass(this.childClass),
                            data: action,
                            style: _normalizeStyle(_ctx.style),
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick($event.event, $event.data)))
                          }, null, 8, ["actions-func-dict", "class", "data", "style"]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["onHide"]))
                : _createCommentVNode("", true)
            ], 64))
      ], 64))
    : _createCommentVNode("", true)
}