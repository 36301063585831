import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavLink = _resolveComponent("AppNavLink")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, _mergeProps({
    "active-class": _ctx.activeClass,
    "aria-current-value": _ctx.ariaCurrentValue,
    "exact-active-class": _ctx.exactActiveClass,
    replace: _ctx.replace,
    to: _ctx.to,
    custom: ""
  }, _ctx.$attrs), {
    default: _withCtx(({ href, route, navigate, isActive, isExactActive }) => [
      _createVNode(_component_AppNavLink, {
        class: _normalizeClass(this.class),
        href: href,
        "is-active": isActive,
        "is-exact-active": isExactActive,
        mark: _ctx.mark,
        navigate: navigate,
        route: route,
        style: _normalizeStyle(_ctx.style),
        target: _ctx.target,
        onIsActiveChanged: _ctx.onIsActiveChanged
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, 1032, ["class", "href", "is-active", "is-exact-active", "mark", "navigate", "route", "style", "target", "onIsActiveChanged"])
    ]),
    _: 3
  }, 16, ["active-class", "aria-current-value", "exact-active-class", "replace", "to"]))
}