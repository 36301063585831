import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import { AuthenticationService } from "@/services/AuthenticationService";
import {
  CalcCategoryRao,
  CalcCodeRaoDemo,
  NotificationsService,
  TTestDynamicModalComponent,
} from "table";

// todo переписать на route guards
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main-menu",
    component: () => import("../modules/main-menu/DynamicMainMenu.vue"),
    meta: { auth: true },
    strict: true,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../modules/authentication/AuthenticationDemo.vue"),
    meta: { notAuth: true },
  },
  {
    path: "/editor-forms/:id",
    name: "Редактор форм",
    component: () => import("../modules/editor-forms/EditorFormsDemo.vue"),
    meta: { auth: true },
  },
  {
    path: "/reg-reports-v2",
    name: "Журнал регистрации отчётов v2",
    component: () =>
      import("../modules/registration-reports/RegistrationReportsDemoV2.vue"),
    meta: { auth: true },
  },
  {
    path: "/reg-reports-v3/:journal_name",
    component: () =>
      import(
        "../modules/registration-reports/components/ReportPanelList/ReportPanelListV3.vue"
      ),
    meta: { auth: true },
    props: (route) => route.query,
  },
  {
    path: "/editing-voc/:voc_type",
    name: "Список значений справочника",
    component: () =>
      import("../modules/editing-voc/components/EditingVocEntries.vue"),
    meta: { auth: true },
    strict: true,
  },
  {
    path: "/browse_edit/:page_name",
    name: "Универсальный редактор справочника",
    component: () => import("../modules/browse-edit/BrowseEdit.vue"),
    meta: { auth: true },
    strict: true,
  },
  {
    path: "/analytical-reports",
    name: "Аналитическая отчётность",
    component: () =>
      import(
        "../modules/analytical-reports/components/AnalyticalReportsList.vue"
      ),
    meta: { auth: true },
    strict: true,
    props: (route) => ({
      aReportListUrl: route.query.a_report_list_url,
    }),
  },
  {
    path: "/analytical-reports/:a_report_id",
    name: "Аналитический отчёт",
    component: () =>
      import(
        "../modules/analytical-reports/components/AnalyticalReportsReport.vue"
      ),
    meta: { auth: true },
    strict: true,
  },
  /** @deprecated */
  {
    path: "/calc/code-rao",
    name: "Калькулятор кода РАО",
    component: CalcCodeRaoDemo,
    meta: { auth: true },
    strict: true,
  },
  /** @deprecated */
  {
    path: "/calc/category-rao",
    name: "Калькулятор категории РАО",
    component: CalcCategoryRao,
    meta: { auth: true },
    strict: true,
  },
  {
    path: "/settings-appearance",
    name: "Настройка внешнего вида",
    component: () =>
      import("../modules/settings-appearance/SettingsAppearance.vue"),
    meta: { auth: true },
    strict: true,
  },
  {
    path: "/analytical-report-create",
    name: "Создание аналитического отчёта",
    component: () =>
      import(
        "../modules/analytical-reports/components/AnalyticalReportsCreateReport.vue"
      ),
    meta: { auth: true },
    strict: true,
    props: (route) => ({
      aReportParamsUrl: route.query.a_report_params_url,
      aReportCreateUrl: route.query.a_report_create_url,
      isShowBodyCloseButton: false,
    }),
  },
  {
    path: "/tables-and-actions",
    component: () =>
      import("../components/smart/TablesAndActions/TablesAndActions.vue"),
    meta: { auth: true },
    strict: true,
    props: (route) => route.query,
  },
  {
    path: "/import-history-data",
    component: () =>
      import("../modules/import-history-data/ImportHistoryData.vue"),
    meta: { auth: true },
    strict: true,
    props: (route) => route.query,
  },
  {
    path: "/page/default/:backend_page",
    component: () => import("../modules/page/DefaultPage/DefaultPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/page/default-local/:frontend_page",
    component: () => import("../modules/page/DefaultPage/DefaultPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/page/browse-default",
    component: () => import("../modules/page/BrowseDefault/BrowseDefault.vue"),
    meta: { auth: true },
  },
  {
    path: "/test-modal",
    component: TTestDynamicModalComponent,
    meta: { auth: true },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../components/PageNotFound.vue"),
    strict: false,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuth = AuthenticationService.isAuth.value;
  const requireAuth = to.matched.some((record) => record.meta.auth);
  if (requireAuth && !isAuth) {
    next(`/login?redirect=${encodeURIComponent(to.fullPath)}`);
    NotificationsService.send({
      type: "warn",
      title: "Вы не авторизованы",
      text: "Войдите в систему",
      duration: 5000,
    });
    return;
  }

  // если пользователь уже авторизован, то перенаправляем в меню
  if (isAuth && to.matched.some((record) => record.meta.notAuth)) {
    next("/");
    return;
  }

  next();
});

export default router;
