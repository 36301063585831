
import { defineComponent, watch } from "vue";
import { APP_ICONS, HEADER_IDS } from "@/common/consts";
import {
  ApiHelper,
  ButtonComponent,
  DropdownComponent,
  Logger,
  NotificationsService,
  TABLE_ICONS,
} from "table";
import ApiAuthService from "@/services/ApiAuthService/ApiAuthService";
import { AuthenticationService } from "@/services/AuthenticationService";
import release from "../../release.json";
import AppApiService from "@/services/AppApiService/AppApiService";
import { InfoVersionsDto } from "@/services/AppApiService/types";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";
import DynamicMainMenu from "@/modules/main-menu/DynamicMainMenu.vue";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";
import { isDev } from "@/common/helpers/isDev";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    DynamicMainMenu,
    ButtonComponent,
    DropdownComponent,
  },
  setup() {
    return {
      HEADER_IDS,
      TABLE_ICONS,
      APP_ICONS,
      AuthenticationService,
      version: release.version,
    };
  },
  data() {
    return {
      show: {
        menu: false,
      },
      infoVersions: null as InfoVersionsDto | null,
    };
  },
  async created() {
    watch(
      () => this.appTitle,
      () => {
        document.title = this.appTitle;
        if (isDev) {
          document.title = `${process.env.NODE_ENV} ` + document.title;
        }
      },
    );

    await wrapNotifyAndAwaited([
      async () => {
        this.infoVersions = await AppApiService.getInfoGetVersions().then(
          (x) => x.json,
        );
      },
    ]);
  },
  watch: {
    $route() {
      this.show.menu = false;
    },
  },
  computed: {
    isAuth(): boolean {
      return AuthenticationService.isAuth.value;
    },

    isShowMenuButton(): boolean {
      return (
        this.$route.name !== "main-menu" &&
        this.$route.name !== "login" &&
        this.isAuth
      );
    },

    versionsTitle(): string {
      if (this.infoVersions === null) {
        return "Загрузка...";
      }

      return `Фронтенд: ${this.version}, Бэкенд: ${this.infoVersions.backend}, База данных: ${this.infoVersions.database}`;
    },

    appTitle(): string {
      if (this.infoVersions === null) {
        return "Загрузка...";
      }

      return this.infoVersions.app_title ?? "СГУК РВ и РАО";
    },

    rightOptions(): DropdownOption[] {
      return [
        {
          title: "Руководство пользователя",
          onClick: this.onOpenUserGuide,
        },
        {
          title: "Настройки",
          onClick: () => {
            this.$router.push("/settings-appearance");
          },
        },
        {
          title: "Выйти",
          onClick: this.onLogout,
        },
      ];
    },
  },
  methods: {
    async onLogout() {
      try {
        await ApiAuthService.logout();
        AuthenticationService.updateIsAuth();
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при выходе",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
        return;
      }

      NotificationsService.send({
        type: "success",
        title: "Аутентификация",
        text: "Вы вышли из системы",
        duration: 5000,
      });
      await this.$router.push("/login");
    },

    onOpenUserGuide() {
      window.open("/api/info?action=get_user_guide", "_blank");
    },
  },
});
