import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-is-open-header"]
const _hoisted_2 = ["data-is-open-header"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_DynamicMainMenuItem = _resolveComponent("DynamicMainMenuItem")!
  const _component_DynamicComponents = _resolveComponent("DynamicComponents")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      "data-is-open-header": _ctx.boolToString(_ctx.isOpenHeader),
      class: "dynamic-main-menu-container"
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ScreenSpinner, { key: 0 }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listColumns, (listColumn, iListColumn) => {
        return (_openBlock(), _createElementBlock("div", {
          key: listColumn[0].caption ?? iListColumn,
          class: "dynamic-main-menu-column"
        }, [
          _createElementVNode("div", {
            "data-is-open-header": _ctx.boolToString(_ctx.isOpenHeader),
            class: "dynamic-main-menu-root"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listColumn, (item, i) => {
              return (_openBlock(), _createBlock(_component_DynamicMainMenuItem, {
                key: item.caption ?? i,
                "get-open": _ctx.getOpenByItem,
                "is-header": true,
                "is-main-menu-mounted": _ctx.isMounted,
                "is-open-header": _ctx.isOpenHeader,
                item: item,
                "onSwitch:open": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggleMenu($event))),
                "onClick:component": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickComponent($event))),
                onIsActiveChanged: _ctx.onIsActiveChanged
              }, null, 8, ["get-open", "is-main-menu-mounted", "is-open-header", "item", "onIsActiveChanged"]))
            }), 128))
          ], 8, _hoisted_2)
        ]))
      }), 128))
    ], 8, _hoisted_1),
    _createVNode(_component_DynamicComponents, {
      components: _ctx.show.components,
      "onClose:component": _ctx.onCloseComponent,
      "onOpen:component": _ctx.onOpenComponent
    }, null, 8, ["components", "onClose:component", "onOpen:component"])
  ], 64))
}