import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (_, component) => {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(component), {
      key: component,
      onClose: ($event: any) => (_ctx.hideComponent(component)),
      "onOpen:component": _ctx.openComponent
    }, null, 40, ["onClose", "onOpen:component"]))
  }), 128))
}