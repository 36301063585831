
import {
  defineComponent,
  PropType,
} from "vue";
import { Dictionary } from "table";

export default defineComponent({
  name: "DynamicComponents",
  props: {
    components: Object as PropType<Dictionary<boolean>>,
  },
  emits: ["open:component", "close:component"],
  methods: {
    openComponent(component: string) {
      this.$emit("open:component", component);
    },

    hideComponent(component: string) {
      this.$emit("close:component", component);
    },
  },
});
