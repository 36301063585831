import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([
      _ctx.isActive && 'router-link-active',
      _ctx.isExactActive && 'router-link-exact-active',
      this.class,
    ]),
    href: _ctx.href,
    style: _normalizeStyle(_ctx.style),
    target: _ctx.target,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.target === undefined ? _ctx.navigate($event) : undefined))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 14, _hoisted_1))
}