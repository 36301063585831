
import {
  ComponentPublicInstance,
  defineComponent,
  PropType,
  StyleValue,
} from "vue";
import { ActionButtonDto } from "@/services/ApiActions/types";
import {
  ButtonComponent,
  ButtonIconComponent,
  Dictionary,
  GlobalContext,
  Logger,
  TABLE_ICONS,
  TooltipComponent,
} from "table";

export type ActionButtonActionsFuncDict = Dictionary<() => Promise<any> | any>;

export default defineComponent({
  name: "ActionButton",
  components: {
    ButtonComponent,
    TooltipComponent,
    ButtonIconComponent,
  },
  props: {
    class: {},
    childClass: {},
    style: {},
    data: {
      type: [Object, String] as PropType<ActionButtonDto | "delimiter">,
      required: true,
    },
    // если функция вернёт Promise - кнопка будет выключена до его завершения
    actionsFuncDict: {
      type: Object as PropType<ActionButtonActionsFuncDict>,
      required: false,
    },
  },
  emits: ["click"],
  setup() {
    return {
      TABLE_ICONS,
      Boolean,
    };
  },
  data() {
    return {
      open: {
        tooltip: false,
      },
      disabled: false,
    };
  },
  computed: {
    visibleByContextFilter(): boolean | undefined {
      if (!this.dataObj?.context_filter) {
        return undefined;
      }

      const checkedArray = Object.keys(this.dataObj.context_filter).map(
        (path) => {
          const value = GlobalContext.get(path);
          return this.dataObj!.context_filter![path].includes(value);
        },
      );
      return !checkedArray.includes(false);
    },

    visible(): boolean {
      if (this.visibleByContextFilter === false) {
        return false;
      }

      return true;
    },

    isDelimiter(): boolean {
      return this.data === "delimiter";
    },

    dataObj(): ActionButtonDto | undefined {
      if (typeof this.data !== "object") {
        return undefined;
      }

      return this.data;
    },

    props(): Dictionary | undefined {
      const props = this.dataObj?.props || {};
      props.style = this.styleComputed;
      props.class = this.classComputed;
      return props;
    },

    styleComputed(): StyleValue {
      return [
        { whiteSpace: "nowrap" },
        this.style,
        this.dataObj?.props?.style,
      ].filter(Boolean) as StyleValue;
    },

    classComputed(): string | undefined {
      return this.dataObj?.props?.class ?? this.dataObj?.class ?? this.class;
    },
  },
  watch: {},
  created() {
  },
  methods: {
    tooltipComponent(): InstanceType<typeof TooltipComponent> {
      return this.$refs.tooltipRef as any;
    },

    async onClick(event: MouseEvent, data: ActionButtonDto) {
      if (data.action_func) {
        const func = this.actionsFuncDict?.[data.action_func];
        if (typeof func === "function") {
          this.disabled = true;
          try {
            await func();
          } catch (ex) {
            Logger.error(`ActionButton error: ${data.caption}`, {
              ex,
              data,
            });
          }
          this.disabled = false;
        }
      }

      if (data.actions) {
        this.open.tooltip = true;
        this.tooltipComponent().show(event, {
          target: (this.$refs.buttonComponentRef as ComponentPublicInstance)
            .$el,
        });
        return;
      }

      this.onHide();
      this.$emit("click", {
        data,
        event,
      });
    },

    onHide() {
      this.open.tooltip = false;
      this.tooltipComponent()?.hide(false);
    },
  },
});
