
import { defineComponent } from "vue";
import AppNavLink from "@/components/AppNavLink.vue";

const toTypes = [String, Object];

export default defineComponent({
  name: "AppRouterLink",
  components: { AppNavLink },
  props: {
    to: {
      type: toTypes,
      required: true,
    },
    custom: Boolean,
    replace: Boolean,
    activeClass: String,
    exactActiveClass: String,
    ariaCurrentValue: String,
    class: {},
    mark: {},
    target: String,
    style: {},
  },
  emits: ["is-active-changed"],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    onIsActiveChanged(props: any) {
      this.$emit("is-active-changed", props);
    },
  },
});
