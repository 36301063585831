import {
  ApiHelper,
  ApiService,
  Dictionary,
  HttpService,
  isNullOrUndefined,
  NotificationsService,
  sleep,
  Subject,
  TDynamicModalService,
  waitTablesQueue,
} from "table";
import { ActionButtonDto, ActionsProgress } from "@/services/ApiActions/types";
import router from "@/router";
import ProgressDynamicModal, {
  ProgressDynamicModalContext,
} from "@/components/smart/ProgressDynamicModal.vue";
import {
  HttpRequestResult,
  HttpRequestResultAsync,
} from "table/dist/services/Http/HttpService";

export type RefreshType = "record" | "all";

export interface ActionExecRefreshResult {
  caption?: string;
  text?: string;
  refresh?: RefreshType;
  // открывает url в новой вкладке
  url?: string;
  target?: string;
  // открывает url в текущей вкладке
  push_url?: string;
  // скачивает результат по указанной ссылке
  download_url?: string;
  // url на компонент, который откроется в модальном окне
  modal?: string;
  // контекст, который передастся в модалку, если она откроется
  context?: any;
}

export type ActionExecJsonResult =
  | { proc_id: string }
  | ActionExecRefreshResult;

export interface ExecuteExecReturn {
  proc_id?: number | string;
  parseProgress?: ActionsProgress;
  result?: ActionExecRefreshResult;
}

class ApiActionsService {
  getQueryParams = ApiService.getQueryParams;

  async loadingFile(result: HttpRequestResult<ActionExecRefreshResult>) {
    const content = result.response.headers.get("content-disposition");
    if (!content) {
      return;
    }

    const filename = content.split("filename=")[1].split(";")[0];
    const a = document.createElement("a");
    try {
      a.href = URL.createObjectURL(await result.response.blob());
    } catch {
      if (!(result.json && filename)) {
        return;
      }

      let blob = new Blob([JSON.stringify(result.json)], {
        type: "application/json",
      });
      a.href = URL.createObjectURL(blob);
    }
    a.download = filename;
    a.click();
  }

  exec(
    action: Pick<ActionButtonDto, "url" | "data">,
    context: any,
  ): HttpRequestResultAsync<ActionExecJsonResult> {
    return HttpService.post<ActionExecJsonResult>(action.url!, {
      body: JSON.stringify({ context, ...(action.data ?? {}) }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getProgress(
    proc_id: number | string,
  ): HttpRequestResultAsync<ActionsProgress> {
    return HttpService.get<ActionsProgress>(
      `/api/actions?` +
        this.getQueryParams({
          action: "progress",
          proc_id,
        }),
    );
  }

  getResultUrl(proc_id: number | string): string {
    return (
      `/api/actions?` +
      this.getQueryParams({
        action: "result",
        proc_id,
      })
    );
  }

  getResult(
    proc_id: number | string,
  ): HttpRequestResultAsync<ActionExecRefreshResult> {
    return HttpService.request<ActionExecRefreshResult>(
      this.getResultUrl(proc_id),
      { mode: "no-cors" },
    );
  }

  parseProgress(
    proc_id: number | string,
    progressSubject?: Subject<ActionsProgress | undefined>,
  ): Promise<ActionsProgress> {
    return (async () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { json: progressResult } = await this.getProgress(proc_id);
        if (progressResult.status !== "active") {
          progressSubject?.next(undefined);
          return progressResult;
        }

        progressSubject?.next(progressResult);
        await sleep(1000);
      }
    })();
  }

  async parseExec(
    result: HttpRequestResult<ActionExecJsonResult>,
    progressSubject?: Subject<ActionsProgress | undefined>,
    action?: Pick<ActionButtonDto, "url" | "caption">,
  ): Promise<ExecuteExecReturn> {
    if (result.json && "proc_id" in result.json) {
      const proc_id = result.json.proc_id;
      let onMounted: any = () => {};
      const mounted = new Promise((resolve) => {
        onMounted = resolve;
      });
      TDynamicModalService.show(ProgressDynamicModal, {
        progressSubject,
        onCancelProgress: async () => {
          return await this.cancel(proc_id);
        },
        action,
        onMounted,
      } as ProgressDynamicModalContext).then();
      await mounted;
      const parseProgress = await this.parseProgress(proc_id, progressSubject);
      return {
        proc_id,
        parseProgress,
      } as ExecuteExecReturn;
    }

    const parseResult = await this.parseResult({
      response: result.response,
      json: result.json,
    });
    return {
      result: parseResult,
    };
  }

  async parseResult(
    result: HttpRequestResult<ActionExecRefreshResult>,
  ): Promise<ActionExecRefreshResult> {
    const contentType = result.response.headers.has("content-type")
      ? result.response.headers.get("content-type")
      : undefined;
    if (contentType === "application/json") {
      return (await this.processReply(result.json)) ?? {};
    }

    if (contentType === "text/html") {
      const wnd = window.open("about:blank", "", "_blank")!;
      wnd.document.write(await result.response.text());
      return result.json;
    }

    await this.loadingFile(result);
    return result.json;
  }

  async execute(
    action: Pick<ActionButtonDto, "url" | "caption">,
    context: any = undefined,
  ): Promise<ExecuteExecReturn> {
    await waitTablesQueue();
    const progressSubject = new Subject<ActionsProgress | undefined>();
    const resultExec = await this.exec(action, context);
    const parseExec = await this.parseExec(resultExec, progressSubject, action);

    const { parseProgress, proc_id } = parseExec;
    if (isNullOrUndefined(proc_id) || isNullOrUndefined(parseProgress)) {
      return parseExec;
    }

    if (parseProgress.status === "completed") {
      const result = await this.getResult(proc_id);
      return {
        proc_id,
        result: await this.parseResult(result),
      };
    } else if (parseProgress.status === "error") {
      const { status_msg } = parseProgress;
      NotificationsService.send({
        type: "error",
        title: action.caption,
        text: status_msg,
      });
    }

    return parseExec;
  }

  cancel(proc_id: number | string): HttpRequestResultAsync {
    return HttpService.get(
      `/api/actions?` +
        this.getQueryParams({
          action: "cancel",
          proc_id,
        }),
    );
  }

  methodExec<T = Dictionary>(
    path: string,
    name: string,
  ): HttpRequestResultAsync<T> {
    return HttpService.get<T>(
      ApiService.getUrl("/api/methods?", {
        action: "exec",
        path,
        name,
      }),
    );
  }

  actionExec(
    action: Pick<ActionButtonDto, "url" | "caption">,
    context: any = undefined,
  ): Promise<ActionExecJsonResult> {
    return this.exec(action, context).then((x) => x.json);
  }

  actionList<T = ActionExecRefreshResult[]>(path: string): Promise<T> {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "list",
        path,
      }),
    ).then((x) => x.json);
  }

  actionProgress<T = number>(proc_id: string | number): Promise<T> {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "progress",
        proc_id,
      }),
    ).then((x) => x.json);
  }

  actionCancel<T = number>(proc_id: string | number): Promise<T> {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "cancel",
        proc_id,
      }),
    ).then((x) => x.json);
  }

  actionResult<T = Dictionary>(proc_id: string | number): Promise<T> {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "result",
        proc_id,
      }),
    ).then((x) => x.json);
  }

  async processReply(
    reply: ActionExecRefreshResult | undefined,
  ): Promise<ActionExecRefreshResult | undefined> {
    if (!reply) {
      return reply;
    }

    if ("download_url" in reply) {
      await ApiHelper.wrapNotifyError(
        async () => {
          const resultDownload = await HttpService.get(reply.download_url!);
          await this.loadingFile(resultDownload);
        },
        { title: "Ошибка" },
      );
    }

    if (reply.url) {
      window.open(reply.url, reply.target ?? "_blank");
    }

    if (reply.push_url) {
      await router.push(reply.push_url);
    }

    const { text, caption } = reply;
    if (text || caption) {
      NotificationsService.send({
        type: "info",
        title: caption,
        html: text,
      });
    }

    if (reply.modal) {
      const result = await TDynamicModalService.show(
        reply.modal,
        reply.context,
      );
      if (typeof result === "object") {
        return await this.processReply(result);
      }
    }

    return reply;
  }

  getActions(path: string) {
    const url = ApiService.getUrl(`/api/actions?`, {
      action: "list",
      path,
    });
    return HttpService.get<ActionButtonDto[]>(url).then((x) => x.json);
  }
}

export default new ApiActionsService();
