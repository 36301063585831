import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { style: {"position":"absolute","text-align":"right","font-size":"12px","vertical-align":"top","color":"grey","right":"0","bottom":"0","cursor":"help"} }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  class: "nav-footer-modal pointer-events-none",
  style: {"width":"100%","overflow":"auto","background":"rgba(0, 0, 0, 0.7)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DropdownComponent = _resolveComponent("DropdownComponent")!
  const _component_DynamicMainMenu = _resolveComponent("DynamicMainMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: _ctx.HEADER_IDS.root,
      class: "app-header"
    }, [
      _createElementVNode("div", {
        id: _ctx.HEADER_IDS.left,
        class: "app-header-left"
      }, [
        _withDirectives(_createVNode(_component_ButtonComponent, {
          icon: _ctx.APP_ICONS.detail,
          class: "button-opacity",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show.menu = !_ctx.show.menu))
        }, null, 8, ["icon"]), [
          [_vShow, _ctx.isShowMenuButton]
        ]),
        _createVNode(_component_router_link, {
          class: _normalizeClass([{ 'hover-underline cursor-pointer': _ctx.$route.path !== '/' }, "app-h-s22-h24-w7-c333 clear-text-decoration cursor-default"]),
          title: _ctx.versionsTitle,
          style: {"display":"flex","align-items":"center","position":"relative","min-width":"152px","vertical-align":"middle","text-underline":"none","padding":"15px 0"},
          to: "/"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.appTitle), 1),
            _createElementVNode("div", _hoisted_3, " версия " + _toDisplayString(_ctx.version), 1)
          ]),
          _: 1
        }, 8, ["class", "title"])
      ], 8, _hoisted_2),
      _createElementVNode("div", {
        id: _ctx.HEADER_IDS.right,
        class: "app-header-right"
      }, [
        (_ctx.AuthenticationService.user.value)
          ? (_openBlock(), _createBlock(_component_DropdownComponent, {
              key: 0,
              options: _ctx.rightOptions,
              "class-button": "button-opacity",
              containerWidth: "initial",
              mode: "right",
              style: {"order":"999"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.AuthenticationService.user.value?.fio || "Пользователь"), 1)
              ]),
              _: 1
            }, 8, ["options"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_4)
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      id: _ctx.HEADER_IDS.bottom,
      class: "app-header-bottom"
    }, [
      (_ctx.show.menu)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: "nav-footer-modal-overlay",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show.menu = false))
            }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DynamicMainMenu, { "is-open-header": true })
            ])
          ], 64))
        : _createCommentVNode("", true)
    ], 8, _hoisted_5)
  ], 64))
}