
import {
  defineComponent,
  PropType,
} from "vue";
import ActionButton, { ActionButtonActionsFuncDict } from "@/components/ActionButton/ActionButton.vue";
import { ActionButtonDto } from "@/services/ApiActions/types";

export default defineComponent({
  name: "ActionsButtons",
  components: {
    ActionButton,
  },
  props: {
    dataArray: {
      type: Array as PropType<ActionButtonDto[]>,
      default: () => [],
    },
    actionsFuncDict: {
      type: Object as PropType<ActionButtonActionsFuncDict>,
      required: false,
    },
  },
  emits: ["click"],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {
    onClick(event: any) {
      this.$emit("click", event);
    },
  },
});
