
import { defineComponent } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import {
  ContextMenuGlobal,
  NotificationsComponent,
  TDynamicModal,
} from "table";
import { AuthenticationService } from "@/services/AuthenticationService";

export default defineComponent({
  name: "App",
  components: {
    TDynamicModal,
    HeaderComponent,
    NotificationsComponent,
    ContextMenuGlobal,
  },
  setup() {
    return {};
  },
  data() {
    return { isMounted: false };
  },
  created() {
    AuthenticationService.startInterval();
  },
  mounted() {
    this.isMounted = true;
  },
  beforeUnmount() {
    AuthenticationService.stopInterval();
  },
});
