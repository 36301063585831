
import {
  ApiHelper,
  AttrsComponent,
  ButtonComponent,
  Dictionary,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  RepositoryReadModelDto,
  TABLE_ICONS,
  toStr,
} from "table";
import {
  RepositoryVocGroupDetailsDto,
  RepositoryVocGroupHeaderDto,
} from "table/dist/services/Api/types/RepositoryVocGroupDto";
import {
  defineComponent,
  PropType,
} from "vue";
import {
  VocExtraInfo,
  VocGroupDetailsFilled,
  VocGroupHeaderFilled,
} from "@/modules/editing-voc/common/types";
import { getVocGroupEnableAttrs } from "@/modules/editing-voc/common/helpers";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import { VocRepositoryDto } from "table/dist/services/Api/types/VocRepositoryDto";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";
import { GLOBAL_ATTRS_HANDLERS } from "@/common/consts";

// Компонент для отображения цельной записи из справочника
export default defineComponent({
  name: "EditingForm",
  props: {
    vocRepositoryDto: {
      type: Object as PropType<VocRepositoryDto>,
      required: true,
    },
    readOnly: Boolean,
    currentData: {
      type: Object as PropType<Dictionary>,
      required: true,
    },
    defaultOpenHeaders: Boolean,
    uniqueId: {
      type: String,
      required: true,
    },
    vocType: {
      type: String,
      required: false,
    },
    vocExtraInfo: {
      type: Object as PropType<VocExtraInfo>,
      required: false,
    },
    // Объект содержащий свойства только для чтения со значением true. Именно для атрибутов.
    readonlyObject: {
      type: Object as PropType<Dictionary<boolean>>,
    },
  },
  components: {
    CreateVocEntryButton,
    EnterModalComponent,
    EditableHelpComponent,
    AttrsComponent,
    ButtonComponent,
  },
  emits: ["change-field", "reset"],
  setup() {
    return {
      TABLE_ICONS,
      GLOBAL_ATTRS_HANDLERS,
    };
  },
  data() {
    return {
      open: {
        vocHeaders: {} as Dictionary<boolean>,
        currentHelp: null as null | {
          model: RepositoryReadModelDto;
          help: string | undefined;
        },
        enterModal: null as null | EnterModalProps,
      },
    };
  },
  created() {
    this.groupFilled?.forEach((header) => {
      this.open.vocHeaders[header.key] = this.defaultOpenHeaders;
    });
  },
  computed: {
    currentHelpValue(): string | undefined {
      return this.open.currentHelp?.help || this.emptyHelp;
    },

    emptyHelp(): string | undefined {
      if (this.vocExtraInfo?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    modelDict(): Dictionary<RepositoryReadModelDto> {
      return this.vocRepositoryDto.form.table.model.reduce(
        (modelDict, model) => {
          modelDict[model.field] = model;
          return modelDict;
        },
        {} as Dictionary<RepositoryReadModelDto>,
      );
    },

    groupFilled(): VocGroupDetailsFilled[] | undefined {
      const { group } = this.vocRepositoryDto;
      if (!group) {
        return undefined;
      }

      const result: VocGroupDetailsFilled[] = [];
      group.forEach((group, index) => {
        if (group.type !== "details") {
          throw new Error(`group first level not details`);
        }

        const key = this.getGroupDetailsKey(group, index);
        let headers: VocGroupHeaderFilled[];
        if (typeof group.fields[0] === "string") {
          const groupHeader: RepositoryVocGroupHeaderDto = group as any;
          headers = [
            {
              ...groupHeader,
              modelArray: groupHeader.fields
                .map((field) => this.modelDict[field])
                .filter((model) => this.isModelEnable(model)),
              key: `${index}`,
            },
          ];
        } else {
          const headersDto =
            group.fields as any as RepositoryVocGroupHeaderDto[];
          headers = headersDto.map((headerDto, index) => {
            return {
              ...headerDto,
              key: `${toStr(headerDto.caption)}_${index}`,
              modelArray: headerDto.fields
                .map((field) => this.modelDict[field])
                .filter((model) => this.isModelEnable(model)),
            };
          });
        }

        if (headers.length === 0) {
          return;
        }

        result.push({
          ...group,
          key,
          headers: headers.filter((x) => x.modelArray.length !== 0),
        });
      });
      return result;
    },

    enableAttrs() {
      return getVocGroupEnableAttrs(
        this.vocRepositoryDto.form.table.model,
        this.currentData,
      );
    },
  },
  methods: {
    async onUpdateHint(model: RepositoryReadModelDto, text: string) {
      if (!this.vocType) {
        return;
      }

      try {
        await ApiVocService.changeHint(
          {
            voc_type: this.vocType,
            attr: model.field,
          },
          { text },
        );
        model.help = text;
      } catch (ex: any) {
        await ApiHelper.wrapNotifyError(ex, { isError: true });
        return false;
      }

      return true;
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    onOpenHelpEdit() {
      const model = this.open.currentHelp?.model;
      if (!model) {
        return;
      }

      const title = `Редактирование подсказки для атрибута "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.help || "",
        onUpdateModelValue: async (value: string) => {
          if (await this.onUpdateHint(model, value)) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.$emit("change-field", event);
    },
    isModelEnable(model: RepositoryReadModelDto | undefined) {
      if (!model) {
        return false;
      }

      if (!model.show_if_enable_attrs) {
        return true;
      }

      return this.enableAttrs.has(model.field);
    },

    getReadOnly(
      model: RepositoryReadModelDto,
      currentValue: boolean | undefined,
    ) {
      if (this.readonlyObject && this.readonlyObject[model.field]) {
        return true;
      }

      return currentValue;
    },

    getOpenHeader(groupDetailsFilled: VocGroupDetailsFilled): boolean {
      if (
        groupDetailsFilled.caption &&
        groupDetailsFilled.key in this.open.vocHeaders
      ) {
        return this.open.vocHeaders[groupDetailsFilled.key];
      }

      return true;
    },

    getClassAttrsContainer(groupHeaderFilled: VocGroupHeaderFilled): string {
      const classes = ["editing-form-group-attrs-container"];
      if (groupHeaderFilled.attrsClass) {
        classes.push(groupHeaderFilled.attrsClass);
      }

      return classes.join(" ");
    },

    toggleOpenHeader(groupDetailsFilled: VocGroupDetailsFilled) {
      const currentOpen = this.getOpenHeader(groupDetailsFilled);
      this.open.vocHeaders[groupDetailsFilled.key] = !currentOpen;
    },

    getGroupDetailsKey(
      groupDetailsDto: RepositoryVocGroupDetailsDto,
      index: number,
    ) {
      return `${groupDetailsDto.caption}_${index}`;
    },
  },
});
