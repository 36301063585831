import "./main-css";
import { createApp } from "vue";
import App from "./App.vue";
import { install, Logger } from "table";
import router from "@/router";
import { initUserSettings } from "@/services/UserSettingsService/helpers";
import "@/services/AuthenticationService";
import release from "../release.json";
import AppApiService from "@/services/AppApiService/AppApiService";
import EditingVocModal from "@/modules/editing-voc/components/EditingVocModal.vue";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import "@/modules/page/PageInstallExternalOptions";
import { initGlobalTableSettings } from "@/initGlobalTableSettings";

export const appInstance = createApp(App);
appInstance.component(EditingVocEntry.name, EditingVocEntry);
appInstance.component(EditingVocModal.name, EditingVocModal);
appInstance.component(EditingVocExtendedModal.name, EditingVocExtendedModal);
install(appInstance).use(router);

let isMounted = false;

function mountServerUnavailable() {
  const appInstance = createApp({
    template: `
      <div class="app-container">
        <div class="flex-full-center app-h-s16-h24-w7-c333" style="color: red;">Сервер недоступен</div>
      </div>
`,
  });
  appInstance.mount("#app");
  isMounted = true;
}

Promise.all(
  [
    AppApiService.getInfoGetVersions().catch((ex) => {
      if (ex instanceof Error && ex.message === "Failed to fetch") {
        mountServerUnavailable();
        return;
      }

      if (ex && "response" in ex) {
        const status = ex.response.status;
        if (status === 500) {
          mountServerUnavailable();
          return;
        }
      }
      return;
    }),
    initUserSettings(),
    initGlobalTableSettings(),
  ].map((x) =>
    x.catch(() => {
      return;
    }),
  ),
)
  .then(([versionsResult, userSettings, _]) => {
    if (isMounted) {
      return;
    }

    appInstance.mount("#app");
    isMounted = true;
    const versionsJson = (versionsResult as any)?.json ?? {};
    console.table({ ...release, ...versionsJson });
  })
  .catch((e: any) => {
    Logger.error("init user settings exception:", e);
  });
