import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "editing-voc-standard-demo-container" }
const _hoisted_2 = { class: "editing-voc-standard-demo" }
const _hoisted_3 = { class: "editing-voc-standard-demo-header" }
const _hoisted_4 = {
  key: 1,
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"20px"}
}
const _hoisted_5 = {
  key: 1,
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"20px"}
}
const _hoisted_6 = { class: "editing-voc-standard-demo-toolbar" }
const _hoisted_7 = {
  key: 0,
  style: {"background":"#ffffff","box-shadow":"0px 2px 6px rgba(0, 50, 116, 0.25)","border-radius":"4px","padding":"20px 24px 24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_EditingVocEntry = _resolveComponent("EditingVocEntry")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    style: { flexGrow: 1 },
    visible: true,
    "body-style": "padding: 0px;",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.mode === 'edit')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.vocValueDto === null && !_ctx.error)
                    ? (_openBlock(), _createBlock(_component_CircleLoader, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.vocValueDto)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.vocValueDto.label), 1))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Создание записи в справочнике "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.mode === 'edit' && _ctx.vocValueDto)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.edit)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 0,
                        class: "form-button",
                        "data-type": "danger",
                        onClick: _ctx.onRemove
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Удалить ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.to_archive)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 1,
                        class: "form-button",
                        "data-type": "danger",
                        onClick: _ctx.onArchive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Архивировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.to_make_active)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 2,
                        class: "form-button",
                        onClick: _ctx.onMakeActive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сделать активной ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.edit)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 3,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onEditCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Редактировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 4,
                        class: "form-button",
                        onClick: _ctx.onCancelEditAndClose
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Закрыть без сохранения ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status !== _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 5,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onSaveCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status === _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 6,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onSaveCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить черновик ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status === _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 7,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSaveCurrentVersion(true)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить и опубликовать ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_ButtonComponent, {
                    class: "form-button",
                    "data-type": "accent",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createEntry(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Сохранить черновик ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ButtonComponent, {
                    class: "form-button",
                    "data-type": "accent",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createEntry(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Сохранить и опубликовать ")
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_ButtonComponent, {
              icon: _ctx.TABLE_ICONS.close,
              class: "button-opacity",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["icon"])
          ]),
          (_ctx.vocRepositoryDto && _ctx.init)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_EditingVocEntry, {
                  "current-data": _ctx.currentData,
                  "default-open-headers": _ctx.mode === 'create',
                  "read-only": _ctx.readOnly,
                  "readonly-object": _ctx.vocValueDto?.edit?.readonly?.attrs,
                  "unique-id": _ctx.voc_type,
                  "voc-extra-info": _ctx.vocExtraInfo,
                  "voc-repository-dto": _ctx.vocRepositoryDto,
                  "voc-type": _ctx.voc_type,
                  onReset: _ctx.onResetField,
                  onChangeField: _ctx.onChangeField
                }, null, 8, ["current-data", "default-open-headers", "read-only", "readonly-object", "unique-id", "voc-extra-info", "voc-repository-dto", "voc-type", "onReset", "onChangeField"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}