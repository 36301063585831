
import {
  defineComponent,
  PropType,
} from "vue";
import {
  ApiHelper,
  boolToString,
  ButtonComponent,
  CircleLoader,
  dateFormat,
  TABLE_ICONS,
  VOC_NAMES_DICT,
} from "table";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";

export default defineComponent({
  name: "EditingVocHistoryVersions",
  components: {
    ButtonComponent,
    CircleLoader,
  },
  emits: [
    "close",
    "select-version", // (VocValueDto)
  ],
  props: {
    visible: Boolean,
    currentVocValueDto: {
      type: Object as PropType<VocValueDto>,
      required: true,
    },
  },
  setup() {
    return {
      TABLE_ICONS,
      boolToString,
      dateFormat,
    };
  },
  data() {
    return {
      vocValueDtoVersions: null as null | VocValueDto[],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    init() {
      return ApiHelper.wrapNotifyError(
        async () => {
          const result = await (async () => {
            if (
              this.currentVocValueDto.voc_type === VOC_NAMES_DICT.organizations
            ) {
              if (!this.currentVocValueDto.attrs.KOD_ORG) {
                return {
                  result: [],
                  count: 0,
                };
              }

              return (
                await ApiVocService.vocsOrganizationsListVersions(
                  this.currentVocValueDto.record_id,
                )
              ).json;
            }

            return (
              await ApiVocService.vocsListVersionsByValueId(
                this.currentVocValueDto.value_id,
              )
            ).json;
          })();
          this.vocValueDtoVersions = result.result;
        },
        { title: "Загрузка версий записи" },
      );
    },
    emitClose() {
      this.$emit("close");
    },
    emitSelectVersion(vocValueDto: VocValueDto) {
      this.$emit("select-version", vocValueDto);
    },
  },
  watch: {
    async currentVocValueDto() {
      await this.init();
    },
  },
});
