import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "default-page-container default-page-padding-inline",
  style: {"width":"300px","z-index":"1","background-color":"white"}
}
const _hoisted_2 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-end","margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CircleLoader, {
        progress: _ctx.progressPercent,
        zoom: 1
      }, null, 8, ["progress"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ButtonComponent, {
        class: "form-button",
        "data-type": "danger",
        onClick: _ctx.onCancelProgress
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Отмена ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}