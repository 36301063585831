
import {
  defineComponent,
  inject,
  PropType,
} from "vue";
import {
  ButtonComponent,
  CircleLoader,
  Dictionary,
  FormContext,
  RepositoryReadModelDto,
  TABLE_ICONS,
  TableModel,
  TDynamicModalData,
} from "table";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import { IApiDefaultPageAndPageFrontendApi } from "@/modules/page/types";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";
import ApiActionsService, { ExecuteExecReturn } from "@/services/ApiActions/ApiActionsService";

interface FormDynamicContext {
  model_url: string;
  read_url?: string;
  save_url?: string;
  commit_url?: string;
  title?: string;
  defaults?: Dictionary;
}

export default defineComponent({
  name: "FormDynamic",
  props: {
    context: {
      type: Object as PropType<FormDynamicContext>,
      required: true,
    },
    form: {
      type: Object as PropType<TDynamicModalData["formProp"]>,
      required: true,
    },
  },
  provide: {
    form: Object,
    api: Object,
  },
  components: {
    ButtonComponent,
    CircleLoader,
    EditingVocEntry,
  },
  setup() {
    const api = inject<IApiDefaultPageAndPageFrontendApi>("api")!;
    return {
      TABLE_ICONS,
      api,
    };
  },
  data() {
    return {
      loading: true,
      formContext: new FormContext({ data: this.context.defaults }),
    };
  },
  async created() {
    this.form.set_caption(this.context.title);
    await wrapNotifyAndAwaited([
      this.formContext.loadRepository(this.context.model_url),
      this.context.read_url ? this.formContext.loadData(this.context.read_url) : undefined,
    ]);
    this.loading = false;
  },
  computed: {},
  methods: {
    getTypeAttr(model: TableModel) {
      return this.formContext.data.repository?.types[model.type || ""];
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.formContext.data.currentData[event.model.field] = event.value;
    },

    onResetField(model: TableModel) {
      const type = this.getTypeAttr(model);
      this.formContext.data.currentData[model.field] = this.formContext.data.initialData[model.field] ?? type?.default;
    },

    getInitFormData() {
      return {
        attrs: {} as Dictionary,
      };
    },

    async onCommit() {
      const {
        save_url,
        commit_url,
      } = this.context;
      if (save_url) {
        await this.formContext.saveData(save_url);
      }

      let result: ExecuteExecReturn | undefined = undefined;
      if (commit_url) {
        result = await ApiActionsService.execute(
          { url: commit_url },
          this.formContext.data.currentData,
        );
      }

      this.api.modal.commit(this, result);
    },
  },
});
