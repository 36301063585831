
import {
  defineComponent,
  PropType,
  watch,
} from "vue";
import { DynamicMainMenuItemDto } from "@/services/AppApiService/types";
import {
  boolToString,
  ButtonComponent,
  TABLE_ICONS,
  TDynamicModalService,
} from "table";
import AppRouterLink from "@/components/AppRouterLink.vue";
import { CALC_COMPONENTS_DICT } from "@/common/consts";
import AppNavLink from "@/components/AppNavLink.vue";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { ClassValueFixDeep } from "table/dist/types/ClassValueFixDeep";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";

export default defineComponent({
  name: "DynamicMainMenuItem",
  components: {
    AppRouterLink,
    ButtonComponent,
    ActionButton,
  },
  props: {
    item: {
      type: Object as PropType<DynamicMainMenuItemDto>,
      required: true,
    },
    headerItem: {
      type: Object as PropType<DynamicMainMenuItemDto>,
      required: false,
    },
    getOpen: {
      type: Function as PropType<(item: DynamicMainMenuItemDto) => boolean>,
      required: true,
    },
    isHeader: Boolean,
    isOpenHeader: {
      type: Boolean,
      default: false,
    },
    isMainMenuMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["switch:open", "click:component", "is-active-changed"],
  setup() {
    return {
      TABLE_ICONS,
      boolToString,
      ApiActionsService,
    };
  },
  data() {
    return {};
  },
  computed: {
    headerButtonClass(): ClassValueFixDeep {
      return {
        "button-opacity app-h-s22-h24-w7-c333 dynamic-main-menu-header-button":
        this.isHeader,
        "app-h-s18-h24-w7-c333": !this.isHeader,
      };
    },
  },
  watch: {},
  created() {
    watch(
      this.item,
      () => {
        // если в экшен кнопке не задан caption, то берём его из объекта меню кнопки
        if (
          this.item.action &&
          this.item.caption !== this.item.action.caption
        ) {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.action.caption = this.item.caption;
        }
      },
      { immediate: true },
    );
  },
  methods: {
    onToggleMenu(item: DynamicMainMenuItemDto) {
      this.$emit("switch:open", item);
    },

    onClickComponent(component: string) {
      this.$emit("click:component", component);
    },

    onClickModal(item: DynamicMainMenuItemDto) {
      if (!item.modal) {
        return;
      }

      TDynamicModalService.show(item.modal, item.context);
    },

    getCaptionComponent(component: string) {
      return CALC_COMPONENTS_DICT[component]?.title;
    },

    onIsActiveChanged(appNavLink: InstanceType<typeof AppNavLink>) {
      this.$emit("is-active-changed", appNavLink);
    },
  },
});
