import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vocExtraInfo?.edit.create_update === true)
      ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 0,
          icon: _ctx.APP_ICONS.documentOutline,
          class: "button-opacity",
          title: "Создать новую запись в справочнике",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open.vocEntryModal = { mode: 'create' }))
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.open.vocEntryModal && _ctx.vocRepositoryDto && _ctx.vocExtraInfo)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(
      _ctx.voc_type === _ctx.VOC_NAMES_DICT.organizations
        ? 'EditingVocExtendedModal'
        : 'EditingVocModal'
    ), {
          key: 1,
          mode: _ctx.open.vocEntryModal.mode,
          record_id: _ctx.open.vocEntryModal.record_id,
          "voc-extra-info": _ctx.vocExtraInfo,
          "voc-repository-dto": _ctx.vocRepositoryDto,
          voc_type: _ctx.voc_type,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open.vocEntryModal = null)),
          onSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('success', $event)))
        }, null, 40, ["mode", "record_id", "voc-extra-info", "voc-repository-dto", "voc_type"]))
      : _createCommentVNode("", true)
  ], 64))
}