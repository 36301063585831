import * as Vue from "vue";
import { App } from "vue";
import { PageService } from "table";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import { AuthenticationService } from "@/services/AuthenticationService";
import TablesAndActions from "@/components/smart/TablesAndActions/TablesAndActions.vue";
import { PageFrontendApi } from "@/modules/page/types";
import EditingForm from "@/modules/editing-voc/components/EditingForm.vue";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import FormDynamic from "@/components/FormDynamic.vue";

function install(Vue: Vue.App<Element>): App<Element> {
  Vue.component(ActionButton.name, ActionButton);
  Vue.component(ActionsButtons.name, ActionsButtons);
  Vue.component(TablesAndActions.name, TablesAndActions);
  Vue.component(EditingForm.name, EditingForm);
  Vue.component(CreateVocEntryButton.name, CreateVocEntryButton);
  Vue.component(EditingVocEntry.name, EditingVocEntry);
  Vue.component(FormDynamic.name, FormDynamic);
  return Vue;
}

const frontend = {
  ApiActionsService,
  AuthenticationService,
};

const api = {
  frontend,
  method_exec: ApiActionsService.methodExec.bind(ApiActionsService),
  action: {
    list: ApiActionsService.actionList.bind(ApiActionsService),
    progress: ApiActionsService.actionProgress.bind(ApiActionsService),
    cancel: ApiActionsService.actionCancel.bind(ApiActionsService),
    result: ApiActionsService.actionResult.bind(ApiActionsService),
    process_reply: ApiActionsService.processReply.bind(ApiActionsService),
    execute: ApiActionsService.execute.bind(ApiActionsService),
    exec: ApiActionsService.actionExec.bind(ApiActionsService),
  },
} as PageFrontendApi;

PageService.setExternalOptions({
  api,
  install,
});
