import { ApiService, HttpService } from "table";
import {
  DynamicMainMenuItemDto,
  ExportWirthTitlePages,
  InfoVersionsDto,
} from "@/services/AppApiService/types";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import {
  VocDto,
  VocValueDto,
} from "table/dist/services/Api/types/VocRepositoryDto";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";

class AppApiService {
  getQueryParams = ApiService.getQueryParams;

  getInfoGetVersions() {
    return ApiService.request<InfoVersionsDto>(
      "/api/info?action=get_versions",
      undefined,
      true,
    );
  }

  exportWithTitlePages(form_id: Array<number | string>) {
    const url = ApiService.getUrl("/api/export_manager?", {
      action: "export_with_title_pages",
      form_id,
    });
    return HttpService.get<ExportWirthTitlePages>(url);
  }

  getVocsTypesList() {
    const url = ApiService.getUrl("/api/repository/vocs/types?", {
      action: "list",
    });
    return ApiService.request<ApiListWrapper<VocDto>>(url, undefined, true);
  }

  async getVocExtraInfo(voc_type: string) {
    const url = ApiService.getUrl("/api/vocs?", {
      action: "get_extra_info",
      voc_type,
    });
    return (await HttpService.get<VocExtraInfo>(url)).json;
  }

  saveVocValueDto(
    vocValueDto: Omit<
      VocValueDto,
      "record_id" | "valid_from" | "valid_to" | "value_id" | "label"
    >,
  ) {
    return HttpService.post<VocValueDto>(
      `/api/vocs?action=save&voc_type=${vocValueDto.voc_type}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vocValueDto),
      },
    );
  }

  saveVocValueDtoNewVersion(vocValueDto: VocValueDto) {
    return HttpService.post<VocValueDto>(
      `/api/vocs?` +
        this.getQueryParams({
          action: "save_new_version",
          record_id: vocValueDto.record_id,
        }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vocValueDto),
      },
    );
  }

  updateVocValueDto(record_id: number | string, vocValueDto: VocValueDto) {
    return HttpService.post<VocValueDto>(
      `/api/vocs?action=update&record_id=${record_id}&voc_type=${vocValueDto.voc_type}`,
      {
        body: JSON.stringify(vocValueDto),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  // запустить экспорт
  exportManagerExportToFile(data: {
    export_form_id: string;
    object_id: number;
    object_type: string;
  }) {
    window.open(
      "/api/export_manager?" +
        this.getQueryParams({
          action: "export_to_file",
          ...data,
        }),
      "_blank",
    );
  }

  getMainMenu() {
    const url = "/api/app-menu";
    return ApiService.useCache(url, () =>
      HttpService.get<DynamicMainMenuItemDto[]>(url),
    );
  }
}

export default new AppApiService();
