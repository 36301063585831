import {
  ApiHelper,
  Logger,
  WrapNotifyErrorParams,
} from "table";

/** @description Оборачивает каждый promise или function в вывод ошибки из API и ожидает выполнения их всех */
export async function wrapNotifyAndAwaited(
  promiseOrFunctionArray: Array<
    Promise<any> | (() => Promise<any>) | undefined
  >,
  _params?: WrapNotifyErrorParams<any> | undefined,
) {
  const promises: Promise<void>[] = [];
  // помещаем все задачи в promises
  promiseOrFunctionArray.forEach((promiseOrFunc, i) => {
    promises.push(
      ApiHelper.wrapNotifyError(() => {
        const promise =
          typeof promiseOrFunc === "function" ? promiseOrFunc() : promiseOrFunc;
        if (promise === undefined) {
          return;
        }
        
        // выводим все ошибки в консоль
        promise.catch((error) => {
          Logger.error(`wrapNotifyAndAwaited ${i}:`, error);
        });

        return promise;
      }, _params),
    );
  });
  await Promise.all(promises.filter(Boolean));
}
